import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom'
import Home from "../pages/Home";

class Main extends Component {

  render() {
    return (
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </main>
    );
  }
}

export default Main;
