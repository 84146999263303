import React from 'react';
import { Image } from 'react-bootstrap';
import '../css/imagebox.css';


const ImageBoxComponent = (props) => {
  
    
    return (
      <div className='imageBoxContainer'>
        <div>
          <Image className='image_box' src={props.imageSrc} />
        </div>  
        <div className='title'>
          {props.title}
        </div>
        <div className='description'>
          {props.description}
        </div>  
      </div>
    );
  };
  
  export default ImageBoxComponent;

