import React, { Component } from 'react';

class Footer extends Component {

  render() {

    var style = {
      backgroundColor: "#F8F8F8",
      borderTop: "1px solid #E7E7E7",
      textAlign: "center",
      padding: "20px",
      position: "fixed",
      left: "0",
      bottom: "0",
      height: "60px",
      width: "100%",
    }

    var phantom = {
      display: 'block',
      padding: '20px',
      height: '60px',
      width: '100%',
    }

    return (
      <footer>
          <div style={phantom} />
          <div style={style}>
            <a href="tel:925-818-8193">Call Kellie at (925) 818-8193</a>
          </div>
      </footer>
    );
  }
}

export default Footer;
