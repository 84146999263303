import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../App.css';
import model from '../model/model.json';
import SquareApptComponent from '../hooks/SquareupAppts';
import ImageBoxComponent from '../hooks/ImageBox';

class Home extends React.Component {

  constructor() {
    super();

    this.state = {

    }
  }

  generateSkillsWidgets() {
    
    var divs = [];

    for (var key in model.services) {
      const title = model.services[key].title;
      const description = model.services[key].description;
      const imageSrc = model.services[key].image_src;
      
      divs.push(
        <ImageBoxComponent title={title} description={description} imageSrc={imageSrc}/>
      )
    }

    return divs;
  }

  render() {
    return (
      <div className='homeContainer'>
        <Container>
          <Row>
            <div className='taglineContainer'>
              <div className='tagline'>
                Inner Peace Spa has treated clients that fall on all spectrums of skin type, 
                from aging clients that are in need of reducing fine lines, skin pigmentation 
                and dryness, to teenage clients that have acneic skin.
              </div>
            </div>
          </Row>
          <Row>
            <Col key={"left"} md={12}>
              <SquareApptComponent />
            </Col>
          </Row>
          <Row>
            <Col key={"right"} md={12}>
              <div className='skillsContainer'>
                {this.generateSkillsWidgets()}
              </div>
            </Col>
          </Row>
        </Container>
      </div>   
    );
  }
}

export default Home;
