import React, { Component } from 'react';
import '../App.css';
import { Image } from 'react-bootstrap';
import logo from '../assets/header_bg_logo.png';

class Header extends Component {

  render() {
    return (
      <header>
        <div className="banner">
          <div className="imagesContainer">
            <Image
              className="logoImage"
              src={logo}
            />
          </div>
          <div className="taglineContainer">
            <div className="subtitle">
            225 Alamo Plaza Suite A
            </div>
            <div className="subtitle">
            Alamo, CA 94507
            </div>
            <div className="subtitle">
            Call Kellie at <a href="tel:925-818-8193">(925) 818-8193</a>
            </div>  
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
