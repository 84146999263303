import React, { useEffect } from 'react';
// import { Figure } from 'react-bootstrap';
import '../css/squareup.css';

// Custom hook to manage a counter

// Component using the custom hook
const SquareApptComponent = () => {
  
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://square.site/appointments/buyer/widget/76761e20-83bd-42a7-b423-932d5f69bb89/4215N1F2SY6MQ.js";
    script.async = true;
    const targetDiv = document.getElementById("square-div");
    targetDiv.appendChild(script);
  }, []);

  return (
    <div className='squareUpApptsContainer'>
      <div className="instruction">
        Book an Appointment:
      </div>
      <div id="square-div">
        

      </div>
    </div>
  );
};

export default SquareApptComponent;
